<template>
    <div>
        <h1>{{checkforTitle}}</h1>
        <div class="content" v-html="checkforText">
        </div>
    </div>
</template>
<script>
    import { mapState } from "vuex";
    export default {
        computed: {
            checkforText() {
                return this.content.BrowserOutdated ? this.content.BrowserOutdated.details.Text : null;
            },
            checkforTitle() {
                return this.content.BrowserOutdated ? this.content.BrowserOutdated.details.Title : null;
            },
            ...mapState(["content"])
        },
        async created() {
            this.$store.dispatch("getContent", 'BrowserOutdated');
        }
    };
</script>

<style lang="less" scoped>
    h1 {
        word-wrap: break-word;
        color: #25167a;
        font-size: 26px;
        text-transform: uppercase;
        letter-spacing: 0.03em;
        font-weight: 400;
        line-height: 1.25;
        padding-left: 240px;
    }

    div.content {
        padding-left: 240px;
        margin: 5px 0 0;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
    }

    @media only screen and (max-width: 767px) {
        h1, div.content {
            padding-left: 0px;
        }
    }
</style>